.quotation-request {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;

    .quotation-request {
        &__inner {
            position: relative;
            float: left;
            width: 100%;
            height: 100%;
            padding: 15px;
            background-image: url('../../../img/vloer-rol-dark.jpg');
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-color: @c-gray-default;
            background-size: cover;
            box-shadow: 3px 5px 12px @c-gray-default;
            box-sizing: border-box;

            @media screen and (min-width: @breakpoint-xsmall) {
                padding: 25px;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                height: 375px;
            }
        }

        &__title {
            display: block;
            margin: 0 0 15px;
            color: @c-white;
            font-size: 22px;
            position: relative;

            @media screen and (min-width: @breakpoint-xsmall) {
                font-size: 25px;
            }
        }

        &__block {
            display: inline-block;
            width: 100%;
        }

        &__form {
            margin: 0;

            .form {
                &__fields {
                    width: 100%;
                }

                &__column {
                    display: flex;
                    flex-direction: column;

                    @media screen and (min-width: @breakpoint-xsmall) {
                        flex-direction: row;
                    }

                    .form__field {
                        &:nth-of-type(1) {
                            @media screen and (min-width: @breakpoint-xsmall) {
                                margin-right: 5px;
                            }

                            @media screen and (min-width: @breakpoint-medium) {
                                margin-right: 10px;
                            }
                        }

                        &:nth-last-of-type(1) {
                            @media screen and (min-width: @breakpoint-xsmall) {
                                margin-left: 5px;
                            }

                            @media screen and (min-width: @breakpoint-medium) {
                                margin-left: 10px;
                            }
                        }
                    }
                }

                &__field {
                    width: 100%;
                    transition: all .15s ease-in;
                }

                &__label {
                    font-size: 14px;
                    color: @c-white;
                    .neuron();
                }

                &__input,
                &__select {
                    width: 100%;
                    height: 35px;
                    padding: 0 10px;
                    margin-bottom: 10px;
                    box-sizing: border-box;
                }

                &__color {
                    display: inline-block;
                    width: 100%;

                    #select_color {
                        margin: 0;
                        width: 100%;
                        border-radius: 4px;
                        box-sizing: border-box;

                        span {
                            width: 120px;
                            font-size: 13px;
                        }
                    }
                }

                &__buttons {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;

                    @media screen and (min-width: @breakpoint-xxsmall) {
                        flex-direction: row;
                        margin-top: 10px;
                    }

                    @media screen and (min-width: @breakpoint-medium) {
                        margin-top: 15px;
                    }
                }

                &__button-holder {
                    flex: 1 1 100%;
                    width: 100%;

                    &:nth-of-type(1) {
                        margin-bottom: 5px;

                        @media screen and (min-width: @breakpoint-xxsmall) {
                            margin-right: 5px;
                            margin-bottom: 0;
                        }

                        @media screen and (min-width: @breakpoint-medium) {
                            margin-right: 10px;
                        }
                    }

                    &:nth-last-of-type(1) {
                        margin-top: 5px;

                        @media screen and (min-width: @breakpoint-xxsmall) {
                            margin-left: 5px;
                            margin-top: 0;
                        }

                        @media screen and (min-width: @breakpoint-medium) {
                            margin-left: 10px;
                        }
                    }
                }

                &__button {
                    display: block;
                    width: 100%;
                    border: 0;
                    border-bottom: 3px solid transparent;
                    font-size: 17px;
                    cursor: pointer;
                    border-radius: 4px;
                    text-align: center;
                    text-decoration: none;
                    padding: 10px;
                    line-height: inherit;
                    box-sizing: border-box;
                    transition: all .15s ease-in;
                    .neuron();

                    &--primary {
                        border-bottom: 3px solid @c-blue-darker;
                        color: @c-white;
                        background-color: @c-blue;

                        &:hover {
                            background-color: @c-blue-dark;
                        }
                    }

                    &--secondary {
                        border-bottom: 3px solid @c-blue-darker;
                        color: @c-white;
                        background-color: @c-grey-dark;

                        &:hover {
                            background-color: @c-grey-darker;
                        }
                    }
                }
            }
        }
    }
}
