.statistics {
    float: left;
    width: 100%;
    padding: 15px 0 7px 0;

    @media screen and (min-width: @breakpoint-xlarge) {
        padding: 15px 0 15px 0;
    }

    .statistics {
        &__inner {
            display: block;
            padding: 0 10px;
            text-align: center;

            @media screen and (min-width: @breakpoint-small) {
                display: flex;
                align-items: center;
                padding: 0;
            }
        }

        &__line {
            float: left;
            width: 8%;
            display: none;

            @media screen and (min-width: @breakpoint-large) {
                display: block;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                width: 15%;
            }

            hr {
                border-bottom: 1px solid @color-blue-primary;
                margin: 0 10px;
            }
        }

        &__content {
            float: left;
            width: 100%;
            font-size: 13px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            @media screen and (min-width: @breakpoint-xsmall) {
                align-items: center;
            }

            @media screen and (min-width: @breakpoint-large) {
                flex-direction: row;
                justify-content: space-around;
            }

            @media screen and (min-width: @breakpoint-large) {
                width: 84%;
            }

            @media screen and (min-width: @breakpoint-grid-large) {
                width: 70%;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            flex-direction: row;

            @media screen and (min-width: @breakpoint-large) {
                flex-direction: column;
                width: auto;
            }

            @media screen and (min-width: @breakpoint-large) {
                flex-direction: row;
            }

            &:first-child {
                margin-left: -12px;
                margin-right: -12px;
            }

            &:not(:nth-last-of-type(1)) {
                margin-bottom: 10px;

                @media screen and (min-width: @breakpoint-large) {
                    margin-bottom: 0;
                }
            }
        }

        &__link {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            strong {
                margin-right: 5px;
            }
        }

        &__reviews {
            strong {
                margin-right: 0;
            }
        }

        &__rating {
            display: block;
            font-size: 14px;
            line-height: 23px;
        }

        &__icon {
            font-size: 19px;
            color: @color-blue-primary;
            margin-right: 10px;
            font-weight: bold;
        }

        &__stars {
            display: inline-block;
            width: 100px;
            height: 16px;
            margin-right: 10px;
            position: relative;

            @media screen and (min-width: @breakpoint-small) {
                display: none;
            }
        }

        &__bar {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('../../../img/rating.jpg');
            background-size: 100px auto;
            background-position: 0 -1px;
            background-repeat: no-repeat;
            left: 0;
            top: 0;

            &--inner {
                background-position: 0 -17px;
                z-index: 0;
                background-size: 100px auto;
            }
        }
    }
}
