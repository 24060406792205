#home-main {
    #home-content {
        float: left;

        .singlesday {
            margin-bottom: 10px;
        }

        @media screen and (max-width: (@breakpoint-medium - 1)) {
            .singlesday {
                width: auto;
            }
        }

        @media screen and (min-width: (@breakpoint-medium)) and (max-width: (@breakpoint-large - 1)) {
            .singlesday {
                width: 676px;
                padding: 0 4px;
            }
        }

        @media screen and (min-width: (@breakpoint-large)) and (max-width: (@breakpoint-xlarge - 1)) {
            .singlesday {
                width: 670px;
                padding: 0 4px;
            }
        }

        #homepage-slider {
            .marb1;

            .carousel-indicators {
                cursor: pointer;

                li {
                    background-color: @color-border-dark;
                }

                .active {
                    background-color: @color-blue-primary;
                }
            }

            #slider {
                margin: 0;
                .box;

                .slide-container {
                    position: relative;
                    min-height: 180px;
                    padding: 23px 37px 23px 37px;

                    .slide-text {
                        float: left;
                        width: 352px;
                        max-width: 100% !important;

                        h3 {
                            font-size: 27px;
                            margin-bottom: 2px;
                            margin-top: 0;
                        }

                        p {
                            font-size: 12px;
                            line-height: 1.5em;
                        }

                        a {
                            position: absolute;
                            line-height: 20px;
                            bottom: 37px;
                            background: @color-blue-primary;
                            font-size: 18px;
                            padding: 5px 31px;
                            color: @c-white;
                            text-decoration: none;
                            .neuron;

                            &:hover {
                                background: @c-blue2;

                                &:after {
                                    border-left-color: @c-blue2;
                                }
                            }

                            &:after {
                                position: absolute;
                                display: block;
                                content: '';
                                top: 0;
                                right: -14px;
                                border-top: 15px solid transparent;
                                border-left: 14px solid @color-blue-primary;
                                border-bottom: 15px solid transparent;
                            }

                            &.cto-green {
                                background: darken(@c-green1, 10%);

                                &:after {
                                    border-left-color: darken(@c-green1, 10%);
                                }

                                &:hover {
                                    background: @c-green1;

                                    &:after {
                                        border-left-color: @c-green1;
                                    }
                                }
                            }
                        }
                    }

                    .slide-image {
                        float: right;

                        img {
                            display: block;
                            margin: 0 auto;
                        }
                    }
                }
            }

            #slider-nav {
                position: relative;
                bottom: 3px;
                overflow-y: hidden;
                .box;
                height: 39px;
                background: #eee;

                .slider-buttons {
                    -webkit-user-select: none;

                    a {
                        box-sizing: border-box;
                        display: inline-block;
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 17px;
                        cursor: pointer;
                        position: relative;
                        left: -1px;
                        padding: 11px 7px;
                        color: @color-text-primary;
                        text-decoration: none;
                        border-left: 1px solid #EEE;
                        border-right: 1px solid #EEE;

                        &.active-slider-button {
                            background: #FFF;
                            border-left-color: @color-border-dark;
                            border-right-color: @color-border-dark;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    .home-items .slick-next {
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        right: -30px;
    }

    .home-items .slick-prev {
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        left: -30px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .home-items .slick-next {
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        right: -7px;
    }

    .home-items .slick-prev {
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        left: -7px;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    .home-items .slick-next {
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        right: -13px;
    }

    .home-items .slick-prev {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        left: -13px;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .home-items .slick-next {
        top: 50%;
        transform: translateY(-50%);
        right: -9px;
    }

    .home-items .slick-prev {
        top: 50%;
        transform: translateY(-50%);
        left: -9px;
    }
}

/* New homepage */

.home-wrapper {
    margin-bottom: 10px;
    float: left;
    width: 100%;

    .home-background {
        float: left;
        position: relative;
        width: 100%;
    }

    .home-h3 {
        font-size: 28px;
        margin: 15px 0 15px 4px;
        float: left;
        width: 100%;
        display: block;
    }

    .resion {
        float: right;
        width: 95px;
        margin-right: 2%;
    }
}

@media screen and (max-width: (@breakpoint-small - 1)) {
    .resion {
        margin-right: 4%;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .resion {
        display: none;
    }
}

@media screen and (max-width: (@breakpoint-xsmall - 1)) {
    .home-wrapper .home-h3 {
        margin-top: 7px;
        font-size: 20px;
    }
}

.home-hero-wrapper {
    float: left;
    width: 100%;
    padding: 0 4px;
}


#home-hero {
    background-color: @c-white;
    float: left;
    width: 100%;
    box-shadow: 2px 2px 6px @c-gray-default;
    margin-bottom: 20px;

    .home-hero-wrapper {
        background-color: @c-white;
    }

    .col-md-6 {
        width: 50%;
        float: left;

        .home-hero-h1 {
            margin: 40px 0 20px 0;
        }

        .home-hero-text {
            margin-bottom: 20px;
            padding-right: 20px;
        }

        .button-cta {
            background: #00A2E0;
            color: @c-white  !important;
            border-radius: 4px;
            padding: 10px 10px;
            border-bottom: 3px solid #005884;
            text-align: center;
            width: 100%;
            float: left;
            display: block;
            max-width: 165px;
            margin: 0 auto 35px auto;
            cursor: pointer;
        }

        .button-cta:hover {
            background: #007eba;
        }
    }

    .home-hero-menu {
        float: left;
        width: 100%;
        box-shadow: 0 -1px 3px @c-gray-default;

        .col-md-3 {
            width: 25%;
            float: left;
            padding: 13px 0;
            box-sizing: border-box;
            text-align: center;
            background: #F2F2F2;
            border-right: 1px solid @c-gray-default;
        }

        .col-md-3:hover {
            background: #e4e4e4;
            ;
        }

        .last {
            border: none;
        }

        .active {
            background: #00A2E0 !important;
            color: @c-white;
        }

        .active:hover {
            color: @c-white  !important;
        }
    }

}

.home-items {
    float: left;
    width: 100%;
}

.home-chosen {
    float: left;
    width: 100%;
}

.home-items>.slick-prev {
    width: 18px;
    height: 29px;
    font-size: 0;
    display: block;
    border: 0;
    background: url(../../img/boot/arrow.png) 0 0 no-repeat;
    position: absolute;
    left: -9px;
    top: 50%;
    cursor: pointer;
    z-index: 100;
    margin-top: -14px;
    transform: translateY(-50%);

    @media screen and (min-width: 472px) {
        left: -15px;
    }

    @media screen and (min-width: @breakpoint-medium) {
        left: -25px;
    }

    @media screen and (min-width: @breakpoint-large) {
        left: -65px;
    }
}

.home-items>.slick-next {
    width: 18px;
    height: 29px;
    font-size: 0;
    display: block;
    border: 0;
    background: url('../../img/boot/arrow.png') -18px 0 no-repeat;
    position: absolute;
    right: -9px;
    top: 50%;
    cursor: pointer;
    z-index: 100;
    margin-top: -14px;
    transform: translateY(-50%);

    @media screen and (min-width: 472px) {
        right: -15px;
    }

    @media screen and (min-width: @breakpoint-medium) {
        right: -25px;
    }

    @media screen and (min-width: @breakpoint-large) {
        right: -65px;
    }
}

.home-chosen>.slick-prev {
    width: 18px;
    height: 29px;
    font-size: 0;
    display: block;
    border: 0;
    background: url('../../img/boot/arrow.png') 0 0 no-repeat;
    position: absolute;
    left: -9px;
    top: 50%;
    cursor: pointer;
    z-index: 100;
    margin-top: -14px;
    transform: translateY(-50%);

    @media screen and (min-width: 472px) {
        left: -15px;
    }

    @media screen and (min-width: @breakpoint-medium) {
        left: -25px;
    }

    @media screen and (min-width: @breakpoint-large) {
        left: -65px;
    }
}

.home-chosen>.slick-next {
    width: 18px;
    height: 29px;
    font-size: 0;
    display: block;
    border: 0;
    background: url(../../img/boot/arrow.png) -18px 0 no-repeat;
    position: absolute;
    right: -9px;
    top: 50%;
    cursor: pointer;
    z-index: 100;
    margin-top: -14px;
    transform: translateY(-50%);

    @media screen and (min-width: 472px) {
        right: -15px;
    }

    @media screen and (min-width: @breakpoint-medium) {
        right: -25px;
    }

    @media screen and (min-width: @breakpoint-large) {
        right: -65px;
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .home {
        #sidebar-left {
            width: 220px;
            padding-right: 0;
        }
    }

    #home-hero {
        background-position: -250% -40px !important;
    }
}

@media screen and (max-width: (@breakpoint-grid-large - 1)) {
    .information-block .support-image {
        display: none;
    }
}

@media screen and (max-width: (@breakpoint-xlarge - 1)) {
    .information-block .blue {
        width: 30%;
    }

    .information-block .information-mid .midden {
        visibility: hidden;
    }

    .home-wrapper .home-h3 {
        margin-top: 7px;
    }

    .information-block .information-mid .info-button .info-button-cta {
        left: 65%;
    }
}

@media screen and (max-width: (@breakpoint-large - 1)) {
    #home-hero {
        background-position: -250% -4000px !important;

        .col-md-first {
            display: none;
        }

        .col-md-6 {
            width: 80%;
        }
    }

    .home #sidebar-left {
        display: none;
    }

    .home #home-main #home-content {
        width: 100% !important;
    }
}

@media screen and (max-width: (@breakpoint-medium - 1)) {
    #home-hero {
        background-position: -250% -40px !important;

        .col-md-first {
            display: block;
        }

        .col-md-6 {
            width: 50%;
        }
    }
}


.support-image {
    min-height: 140px;
    width: 20%;
    float: right;

    img {
        position: absolute;
        height: 146px;
        display: block;
        margin: 0 auto;
        float: right;
        right: 25px;
        bottom: 0;
    }
}

.home {
    #sidebar-left {
        .product-filter {
            padding-right: 10px;
        }
    }
}
